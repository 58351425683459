import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import styled from 'styled-components';
import {device} from '../utils';


// https: //www.gatsbyjs.org/docs/image-tutorial/


class Home extends Component {
  render() {
    const data = this.props.data
    
    return (
      <Layout
        title={`Blog`}
        metaDescription={`My Apron regularly publish blog posts for cooking enthusiasts; sharing cooking tips and exciting news about the new way to manage your recipes.`}
      >
        <h1>Blog</h1>
        <p>Welcome to My Apron's Blog - a place to share all things cooking related.</p>
        <p>While MyApron.co is under development, we will be posting recipes, cooking tips and progress updates, so check back soon!</p>
        <p>If you’re interested in collaborating as a guest blogger, be sure to check out our <a href="/collaboration">Collaboration Page</a>.</p>
        <p>See you in the kitchen...and here too :)</p>

        <Posts>
          {data.allWordpressPost.edges.map(({ node }) => (
            <Link to={`/blog/${node.slug}`}>
              <Title dangerouslySetInnerHTML={{__html: node.title}}></Title>
              <ImageContainer>
                {node.featured_media !== null &&
                  <Img fluid={node.featured_media.localFile.childImageSharp.fluid} alt={node.featured_media.alt_text} />
                }
              </ImageContainer>
              <TextContainer>
                <Excerpt dangerouslySetInnerHTML={{__html: node.yoast_meta.yoast_wpseo_metadesc}} />
              </TextContainer>
            </Link>
        ))}

        </Posts>
      </Layout>
    )
  }
}

export default Home

// Set here the ID of the home page.
export const blogQuery = graphql`
  query AllBlogs {
 
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node{
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
          author { name }
          
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }

          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth:400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

        }
      }
    }
  }
`

const Posts = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-row-gap: 20px;
  grid-column-gap: 30px;

  a {
    min-height: 150px;
    padding: 20px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--red-light);
    box-shadow: 1px 1px var(--red-shadow);
    transition: all 400ms ease-in;
    text-decoration: none;
    
    &:hover, &:active {
      transform: scale(1.04);
      background: var(--red);
      color: #FFF;
    }
  }

  @media ${device.mobileS} {
    display: block;
    a {
      display: block;
      margin: 10px;
    }
  }
  @media ${device.laptop} {
    display: grid;
    a {
      margin: 0;
    }
  }
`;

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    height: 150px;
  }
`;
const TextContainer = styled.div``;
const Title = styled.p`
  font-weight: 400;
  font-size: 1.1em;
  margin-top: 0;
  
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`;
const Excerpt = styled.p``;
